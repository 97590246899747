import React from "react";

import ContactForm from "../../../../ContactForm";

const DieselContact = ({ name = "diesel" }) => {
    return (
        <ContactForm
            urlEnding="diesel"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
        />
    );
};

export default DieselContact;
